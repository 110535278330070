import {
  FC,
  startTransition,
  useRef,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from "react";

import {
  Building20Filled,
  Call20Filled,
  DismissCircle20Filled,
  PersonCircle24Filled,
  Settings20Filled,
  WalletCreditCard20Filled,
} from "@fluentui/react-icons";
import { useLoaderData, useMatchRoute } from "@tanstack/react-router";
import { CopyIcon, XIcon } from "lucide-react";
import { useTooltip } from "react-aria";
import {
  DialogTrigger,
  Provider,
  Toolbar,
  TooltipContext,
  TooltipTriggerStateContext,
} from "react-aria-components";
import { useTooltipTriggerState } from "react-stately";

import { cn, useSafeTimeout, type User } from "@dokworks/shared";
import {
  Button,
  Dialog,
  Link,
  Popover,
  styledToggleButton,
  ToggleButton,
  Tooltip,
  UnstyledLink,
} from "@dokworks/ui";

import { Icon } from "@/components/icon";
import { Logo } from "@/components/logo";

export const Header: FC<{ handleLogout: () => void }> = ({ handleLogout }) => {
  const user = useLoaderData({
    from: "/_auth",
  });

  const matchRoute = useMatchRoute();

  const settingsIsSelected = !!matchRoute({ to: "/settings", fuzzy: true });

  return (
    <Toolbar className="flex h-12 justify-stretch gap-2 px-2">
      <div className="flex flex-1 items-center justify-start gap-2">
        <UnstyledLink
          href="/dashboard"
          className="flex h-9 items-center justify-center overflow-visible rounded-sm outline-none  ring-fg-link data-[focus-visible]:ring-2 narrow:m-0.5 narrow:w-9 regular:ml-10 regular:h-8 regular:px-2"
        >
          <Icon className="size-5 text-fg regular:hidden" />
          <Logo className="-mb-1 h-8 text-fg narrow:hidden" />
        </UnstyledLink>
      </div>
      <div className="flex flex-1 items-center justify-center gap-2"></div>
      <div className="flex flex-1 items-center justify-end gap-1">
        <UnstyledLink
          aria-label="User Settings"
          href="/settings"
          className={(renderProps) =>
            styledToggleButton({
              ...renderProps,
              size: "icon-medium",
              variant: "floating",
              isSelected: settingsIsSelected,
              className: cn({
                "!rounded-b-sm transition-all ease-productive":
                  settingsIsSelected,
              }),
            })
          }
        >
          <Settings20Filled />
        </UnstyledLink>
        <ProfileButton user={user}>
          <MiniProfile user={user} />
        </ProfileButton>
        <Button
          variant="floating"
          size="icon-medium"
          className="mx-1"
          onPress={handleLogout}
        >
          <XIcon className="size-5" />
        </Button>
      </div>
    </Toolbar>
  );
};

interface ProfileButtonProps {
  user: User;
}

function ProfileButton({
  user,
  children,
}: PropsWithChildren<ProfileButtonProps>) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <ToggleButton
        variant="floating"
        size="medium"
        className="font-semibold"
        isSelected={isOpen}
      >
        {user.fullName}
        {isOpen ? (
          <DismissCircle20Filled className="size-6" />
        ) : (
          <PersonCircle24Filled />
        )}
      </ToggleButton>
      <Popover
        placement="bottom end"
        className="w-[18rem] rounded shadow-lg ring-1 ring-brd"
        crossOffset={-16}
        offset={12}
        showArrow
      >
        <Dialog>{children}</Dialog>
      </Popover>
    </DialogTrigger>
  );
}

interface MiniProfileProps {
  user: User;
}

function MiniProfile({ user }: MiniProfileProps) {
  return (
    <>
      <h1 className="mb-2 mt-0.5 font-display text-2xl">{user.fullName}</h1>
      <MiniProfileDetail
        title="Organisatie"
        textValue={user.organisation.name}
        icon={<Building20Filled className="text-fg-accent" />}
      >
        <p className="indent-1 text-sm leading-none">
          {user.organisation.name}
        </p>
      </MiniProfileDetail>
      <MiniProfileDetail
        title="Credits"
        textValue={user.creditBalance.toString()}
        icon={<WalletCreditCard20Filled className="text-fg-accent" />}
      >
        <p
          className={cn("indent-1 font-mono text-sm leading-none", {
            "text-fg-danger": user.creditBalance < 1,
            "text-fg-success": user.creditBalance > 0,
          })}
        >
          {user.creditBalance !== 0 && (
            <span className="select-none">
              {user.creditBalance > 0 ? "+" : "-"}
            </span>
          )}
          {user.creditBalance}
        </p>
      </MiniProfileDetail>
      <MiniProfileDetail
        title="Telefoon"
        textValue={user.phoneNumber}
        icon={<Call20Filled className="text-fg-accent" />}
      >
        {user.phoneNumber ? (
          <div className="flex items-center justify-between">
            <Link
              href={`tel:${user.phoneNumber}`}
              className="indent-1 font-mono text-sm leading-none"
            >
              {user.phoneNumber}
            </Link>
          </div>
        ) : (
          <p className="indent-1 font-mono text-sm leading-none">-</p>
        )}
      </MiniProfileDetail>
    </>
  );
}

interface MiniProfileDetailProps {
  title: string;
  textValue: string;
  icon: ReactNode;
}

function MiniProfileDetail({
  title,
  textValue,
  icon,
  children,
}: PropsWithChildren<MiniProfileDetailProps>) {
  const timeoutId = useRef<number>();
  const { safeClearTimeout, safeSetTimeout } = useSafeTimeout();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [copyMessage, setCopyMessage] = useState<string>("");

  const state = useTooltipTriggerState({ delay: 0 });
  const { tooltipProps } = useTooltip({}, state);

  return (
    <div className="group/user-detail -mx-4 flex items-center gap-2 rounded px-3 py-2 hover:bg-canvas-muted">
      {icon}
      <div className="flex flex-1 flex-col gap-1">
        <h2 className="select-none font-display font-medium leading-none">
          {title}
        </h2>
        {children}
      </div>
      {textValue && (
        <>
          <Button
            aria-label={"Kopieer " + title.toLowerCase()}
            ref={buttonRef}
            size="icon-small"
            variant="ghost"
            className="hidden group-hover/user-detail:inline-flex"
            onPress={handleCopy}
          >
            <CopyIcon className="size-4" />
          </Button>
          <Provider
            values={[
              [TooltipTriggerStateContext, state],
              [
                TooltipContext,
                {
                  ...tooltipProps,
                  triggerRef: buttonRef,
                },
              ],
            ]}
          >
            <Tooltip>{copyMessage}</Tooltip>
          </Provider>
        </>
      )}
    </div>
  );

  async function handleCopy() {
    const type = "text/plain";
    const blob = new Blob([textValue], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    try {
      await navigator.clipboard.write(data);
      startTransition(() => {
        setCopyMessage("Gekopieerd!");
        if (!state.isOpen) {
          state.open();
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        } else if (timeoutId.current !== undefined) {
          safeClearTimeout(timeoutId.current);
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        }
      });
    } catch (e) {
      startTransition(() => {
        setCopyMessage("Kon niet kopiëren.");
        state.open(true);
        if (!state.isOpen) {
          state.open();
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        } else if (timeoutId.current !== undefined) {
          safeClearTimeout(timeoutId.current);
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        }
      });
      console.error(e);
    }
  }
}
