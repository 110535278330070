import { useEffect, useMemo, useState } from "react";

import {
  useMutation,
  useQuery,
  useQueryClient,
  type QueryClient,
} from "@tanstack/react-query";
import {
  createFileRoute,
  type UseNavigateResult,
} from "@tanstack/react-router";
import {
  FilePenLineIcon,
  PlusIcon,
  SignatureIcon,
  Trash2Icon,
} from "lucide-react";
import {
  useDateFormatter,
  type DateFormatterOptions,
  type Key,
} from "react-aria";
import { Selection } from "react-stately";

import type { ResponsePromise } from "@dokworks/fetch";
import { WorkflowType } from "@dokworks/shared";
import {
  Button,
  Cell,
  Column,
  Row,
  Spinner,
  styledButton,
  Table,
  TableBody,
  TableHeader,
  UnstyledLink,
} from "@dokworks/ui";

import { api } from "@/utils/fetch/api";
import {
  dossierDocumentListQueryOptions,
  dossierDocumentQueryOptions,
  dossierQueryOptions,
} from "@/utils/fetch/dossier";
import { workflowListQueryOptions } from "@/utils/fetch/workflow";

const options: DateFormatterOptions = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export const Route = createFileRoute("/_auth/dossier/$dossierId/document/")({
  component: DossierIndexComponent,
});

function DossierIndexComponent() {
  const navigate = Route.useNavigate();
  const { dossierId } = Route.useParams();

  const [selection, setSelection] = useState<Selection>(new Set());

  const queryClient = useQueryClient();
  const { data } = useQuery(dossierDocumentListQueryOptions(dossierId));
  const { data: dossier } = useQuery(dossierQueryOptions(dossierId));

  const availableDocumentTypes = useMemo<{ id: string; type: string }[]>(
    () => dossier?.availableDocuments.map((type) => ({ id: type, type })) ?? [],
    [dossier?.availableDocuments],
  );
  const [documentType, setDocumentType] = useState<Key>(
    availableDocumentTypes[0]?.type,
  );

  useEffect(() => {
    if (availableDocumentTypes.length > 0)
      setDocumentType(availableDocumentTypes[0].type);
  }, [availableDocumentTypes]);
  const { mutate, isPending } = useMutation({
    mutationFn: async (dossierId: string) => {
      const res = await api.post("document/create/", {
        json: { dossier_id: dossierId, document_type: documentType },
      });

      const { documentId } = await res.json<{ documentId: string }>();

      return documentId;
    },
    onSuccess: (documentId, dossierId) => {
      queryClient.invalidateQueries(dossierDocumentListQueryOptions(dossierId));
      queryClient.invalidateQueries(dossierDocumentQueryOptions(documentId));
    },
  });

  const { mutate: deleteMutate, isPending: isPendingDelete } = useMutation({
    mutationFn: async (documentIds: Iterable<Key>) => {
      const promises: ResponsePromise[] = [];

      for (const documentId of documentIds) {
        promises.push(api.delete(`document/${documentId}/`));
      }

      await Promise.all(promises);
    },
    onSuccess: (_, documentIds) => {
      const deletedKeys = Array.from(documentIds);

      setSelection(
        new Set(
          selection === "all"
            ? []
            : Array.from(selection).filter((key) => !deletedKeys.includes(key)),
        ),
      );
    },
    onSettled: (_, err, documentIds) => {
      if (err) {
        console.error(err);
      }

      queryClient.invalidateQueries(dossierDocumentListQueryOptions(dossierId));

      for (const documentId of documentIds) {
        queryClient.invalidateQueries(
          dossierDocumentQueryOptions(documentId.toString()),
        );
      }
    },
  });

  const dateFormatter = useDateFormatter(options);

  return (
    <div className="floating flex flex-1 flex-col gap-4 rounded-tl-sm px-6">
      <h1 className="mt-8 font-display text-3xl font-semibold">
        Documentbeheer
      </h1>
      <div className="mt-2 rounded-md border bg-canvas pt-4 shadow-sm">
        <div className="flex justify-between gap-2 px-4">
          <div className="flex gap-2">
            <Button
              isDisabled={
                isPendingDelete || (selection !== "all" && selection.size < 1)
              }
              variant="danger"
              onPress={() => deleteDocuments(selection)}
            >
              Delete selectie
              {isPendingDelete ? (
                <span className="inline-flex size-5 items-center justify-center">
                  <Spinner size="small" />
                </span>
              ) : (
                <Trash2Icon className="size-5" />
              )}
            </Button>
          </div>
          <div className="flex items-center justify-end gap-2">
            {/* <Select
              selectedKey={documentType}
              onSelectionChange={setDocumentType}
              items={availableDocumentTypes}
              isRequired
            >
              {(item) => <SelectItem id={item.id}>{item.type}</SelectItem>}
            </Select> */}
            <Button
              isDisabled={isPending}
              variant="success"
              className="w-max"
              onPress={() => mutate(dossierId)}
            >
              Genereer nieuw document
              {isPending ? (
                <span className="inline-flex size-5 items-center justify-center">
                  <Spinner size="small" />
                </span>
              ) : (
                <PlusIcon className="size-5" />
              )}
            </Button>
          </div>
        </div>
        <h2 className="px-6 text-center font-display text-lg font-semibold">
          Documenten
        </h2>
        <Table
          aria-label="Dossier documenten"
          className="h-[360px] overflow-y-scroll rounded-md"
          selectionMode="multiple"
          selectionBehavior="toggle"
          selectedKeys={selection}
          onSelectionChange={setSelection}
        >
          <TableHeader>
            <Column isRowHeader textValue="Naam">
              Naam
            </Column>
            <Column textValue="Type">Type</Column>
            <Column textValue="Aangemaakt op" width={240}>
              Aangemaakt op
            </Column>
            <Column textValue="Laatst aangepast op" width={240}>
              Laatst aangepast op
            </Column>
            <Column
              width={availableDocumentTypes.length > 0 ? 260 : 116}
            ></Column>
          </TableHeader>
          <TableBody
            items={data?.results ?? []}
            renderEmptyState={() => (
              <p className="text-center">Geen documenten gevonden.</p>
            )}
            dependencies={[dossier?.availableDocuments.length, data?.results]}
          >
            {function renderRow(item) {
              const createdAt = dateFormatter.format(new Date(item.createdAt));
              const updatedAt = dateFormatter.format(new Date(item.updatedAt));

              return (
                <Row id={item.id}>
                  <Cell textValue={item.name}>{item.name}</Cell>
                  <Cell textValue={item.model}>{item.model}</Cell>
                  <Cell textValue={updatedAt}>{updatedAt}</Cell>
                  <Cell textValue={createdAt}>{createdAt}</Cell>
                  <Cell>
                    <span className="flex items-center gap-1 overflow-hidden border-l px-2">
                      <UnstyledLink
                        href={item.id}
                        className={(renderProps) =>
                          styledButton({
                            ...renderProps,
                            variant: "ghost",
                            className: "text-fg-link",
                          })
                        }
                      >
                        <span className="mt-0.5">Edit</span>{" "}
                        <FilePenLineIcon className="size-5" />
                      </UnstyledLink>
                      {dossier && dossier.availableDocuments.length > 0 && (
                        <SignDocumentButton
                          documentId={item.id}
                          dossierId={dossierId}
                          navigate={navigate}
                          queryClient={queryClient}
                        />
                      )}
                    </span>
                  </Cell>
                </Row>
              );
            }}
          </TableBody>
        </Table>
      </div>
    </div>
  );

  function deleteDocuments(selection: Selection) {
    const keys: Set<Key> =
      selection === "all"
        ? new Set(data?.results.map((item) => item.id) ?? [])
        : selection;
    deleteMutate(keys);
  }
}

type SignDocumentButtonProps = {
  documentId: Key;
  dossierId: string;
  queryClient: QueryClient;
  navigate: UseNavigateResult<"/dossier/$dossierId/document">;
};
function SignDocumentButton({
  dossierId,
  queryClient,
  navigate,
  documentId,
}: SignDocumentButtonProps) {
  const {
    mutate: createDocumentSignWorkflow,
    isPending: isPendingDocumentSignWorkflow,
  } = useMutation({
    mutationFn: async (documentId: Key) => {
      await api.post("workflow_v2/workflow/", {
        json: {
          workflow_type: "TextfeedDocumentSignhost",
          dossier_id: dossierId,
          data: {
            document: {
              reference: documentId,
            },
          },
        },
      });
    },
    onSuccess: () => {
      navigate({
        to: "/dossier/$dossierId/workflows",
        params: {
          dossierId,
        },
      });
    },
    onSettled: (_, err) => {
      if (err) {
        console.error(err);
      }

      queryClient.invalidateQueries(
        workflowListQueryOptions({
          type: WorkflowType.AUTO,
          dossierId,
        }),
      );
    },
  });

  return (
    <Button
      variant="outline"
      className="text-fg-accent"
      isPending={isPendingDocumentSignWorkflow}
      onPress={() => signDocument()}
    >
      <span className="mt-0.5">Onderteken</span>
      {isPendingDocumentSignWorkflow ? (
        <span className="inline-flex size-5 items-center justify-center">
          <Spinner size="small" />
        </span>
      ) : (
        <SignatureIcon className="size-5" />
      )}
    </Button>
  );

  function signDocument() {
    createDocumentSignWorkflow(documentId);
  }
}
